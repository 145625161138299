import React, { useContext, useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"
import gsap from "gsap"
import DesignSection from "../components/organisms/designSection/designSection"
import SEO from "../components/seo"
import { CursorContext } from '../context/cursorContext'
import { useWindowSize } from '../hooks/useWindow'

const TopItem = ({
  data,
  location,
  navigate,
  pageContext,
  transitionStatus,
  entry,
  exit,
}) => {
  const { design } = data
  const { cursorConfig, setCursor } = useContext(CursorContext);
  const wrapper = useRef()
  const [transition, setTransition] = useState('entering')

  const { width } = useWindowSize()

  useEffect(() => {
    if (width < 981) {
      navigate("/designs/")
    }
  }, [width])

  useEffect(() => {
    transitionStatus !== 'entered' && setTransition(transitionStatus)
  }, [])

  useEffect(() => {
    if (transition === "entering") {
      gsap.fromTo(
        wrapper.current,
        {
          y: entry.state.direction === "up" ? "-100vh" : "100vh",
        },
        {
          y: "0vh", duration: 1,
        }
      )
    }
    if (transitionStatus === "exiting") {
      gsap.fromTo(
        wrapper.current,
        { y: "0vh" },
        {
          y: exit.state.direction === "up" ? "100vh" : "-100vh",
          duration: 1,
          onComplete: () => {
            setCursor({ blocked: false })
          },
        }
      )
    }
  }, [transitionStatus, entry, exit])

  console.log(design)

  return (
        <div className="top-wrapper" ref={wrapper}
          onMouseEnter={() => setCursor({
            ...cursorConfig,
            rotation: -30,
            scale: '1',
            color: 'var(--black)',
            shape: "triangle"
          })}
        >
          <SEO
            title={`our top 20 - ${design.acf.meta_title || design.title}`}
            description={design.acf.meta_desc}
            thumb={design.acf?.thumbnail?.sourceUrl}
          />
          <DesignSection
            design={design}
            pageContext={pageContext}
          />
        </div>
  )
}

export default TopItem

export const query = graphql`
  query TopItem($uri: String!) {
    design: wpDesign(uri: { eq: $uri }) {
      id
      title
      uri
      slug
      acf: designMetaFields {
        textLower
        textUpper
        thumbnail {
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        meta_title: textUpper
        meta_desc: textLower
      }
      designFlexFields {
        slideroptions {
          autoplay
          transitionStyle
          transitionTime
        }
        slides {
          type: __typename
          ... on WpDesign_Designflexfields_Slides_Textblock {
            text
            textBackgroundColor {
              custom
              presets
            }
            sideDescription {
              lower
              upper
            }
          }
          ... on WpDesign_Designflexfields_Slides_VideoBlock {
            video
            textoverlay
            format {
              boundaries
              fieldGroupName
              ratio {
                fieldGroupName
                height
                width
              }
              mute
              shadow
              size
            }
            sideDescription {
              lower
              upper
            }
          }
          ... on WpDesign_Designflexfields_Slides_Image {
            fieldGroupName
            format {
              boundaries
              fieldGroupName
              ratio {
                fieldGroupName
                height
                width
              }
              shadow
              size
            }
            sideDescription {
              lower
              upper
            }
            image {
              sourceUrl
              localFile {
                ...AhoyImage
              }
            }
            textoverlay
          }
          ... on WpDesign_Designflexfields_Slides_StopMotion {
            fieldGroupName
            speed
            format {
              boundaries
              fieldGroupName
              ratio {
                fieldGroupName
                height
                width
              }
              shadow
              size
            }
            frames {
              sourceUrl
              localFile {
                ...AhoyImage
              }
            }
            sideDescription {
              fieldGroupName
              lower
              upper
            }
          }
        }

        popUp {
          text
          background {
            custom
            presets
            popupImage {
              sourceUrl
              mediaDetails {
                sizes {
                  width
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`
